<template>
  <div id="About">
    <h1 class="text-white uppercase font-bold text-3xl mb-7">About</h1>
    <hr />
    <div class="container">
      <div class="content">
        <div class="content-group">
          <img src="@/assets/svg/icons/story.svg" />

          <div class="content-group-text">
            <h2 class="text-white text-3xl font-bold uppercase text-right">Our <span>Story</span></h2>
            <div class="divider"></div>
            <br />
            <p class="text-white text-left">We are a group of computer scientist on a mission to make comprehending quantum computing simple! We are a small team each with full time jobs working part time to deliever comprehensive information with eye popping graphics!</p>
        </div>
        </div>
        <br />
        <div class="content-group">
          <img src="@/assets/svg/icons/startup.svg" />

          <div class="content-group-text">
            <h2 class="text-white text-3xl font-bold uppercase text-right">Our <span>Mission</span></h2>
            <div class="divider"></div>
            <br />
            <p class="text-white text-left">Our mission is to make quantum computing comprehensive able in short digistable bites!</p>
        </div>
        </div>
        <br />
        <div class="content-group">
          <img src="@/assets/svg/icons/love.svg" />

          <div class="content-group-text">
            <h2 class="text-white text-3xl font-bold uppercase text-right">Our <span>Why</span></h2>
            <div class="divider"></div>
            <br />
            <p class="text-white text-left">We know that it will be a very long while before quantum computing will be on commercially viable, however we do believe this technology is the next advancement of human civilization! As we provide valuable information, it fills our heart knowing that people become interested in quantum due to the way we frame key concepts!</p>
        </div>
        </div>
      </div>
  </div>

  </div>
</template>

<script>

export default {
  name: 'FAQ',
  components: {
  },
  data(){
    return{
    }
  }
}
</script>

<style scoped>
#About{
  min-height: 90vh;
  font-size: 1rem;
  width: 80%;
  margin: 3rem auto;
}
.divider{
  border:none;
  background-color: var(--primary);
  width: 100%;
  height: 2px;
  margin-top: .2rem 0;
}
.container{
  margin: 2rem;
}
.content{
  transition:1s;
  width: 90%;
}
.content-group img{
  width: 8rem;
  margin: 2rem;
  transition: 1s;
}
.content-group{
  display: flex;
  width: 100%;
}
.content-group-text{
  width: 100%;
}
.content-group-text span{
  color: var(--primary);
}

.question-text{
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
a{
  color: var(--primary);
  text-decoration: none;
}
.logo{
  width: 3rem;
  margin: 2rem auto;
}
@media screen and (max-width: 600px) {
  .container{
    margin-top: .5rem;
    margin-left: .23rem;
    margin-right: .23rem;
  }
  .logo{
    width: 3.5rem;
    margin: 1.3rem auto;
  }
  .content-group img{
    width: 4rem;
    margin: 1rem;
    transition: 1s;
  }
  .content-group-text p{
    font-size: .7rem;
  }
}
</style>
